import { useEffect, useState } from "react"
import {
  AlphanumericValidation,
  DefaultValidations,
  NumericOrEmptyValidation,
  NumericValidation,
  PostalCodeValidation,
  AlphaNumericAndSpecialCharactersValidation,
  NameValidation
} from "../utils/utils"

export const useForm = options => {
  const [data, setData] = useState(options?.initialValues || {})
  const [errors, setErrors] = useState({})

  // Needs to extend unknown so we can add a generic to an arrow function
  const handleChange = e => {
    const key = e.target.id ?? e.target.name
    const value = e.target.value
    setData({
      ...data,
      [key]: value
    })
  }

  const setPropertyValue = (key, value) => {
    setData({
      ...data,
      [key]: value
    })
  }

  const setPropertiesValue = keyValue => {
    setData({
      ...data,
      ...keyValue
    })
  }

  const handleSubmit = async () => {
    const validations = options?.validations
    if (validations) {
      let valid = true
      const newErrors = {}
      for (const key in validations) {
        let internalValid = true
        const value = data ? data[key] : null
        const validation = validations[key]
        if (validation?.required && !value) {
          valid = internalValid = false
          newErrors[key] = validation?.required.message ?? "Campo obligatorio"
        }

        const pattern = validation?.pattern
        if (
          internalValid &&
          pattern?.regx &&
          !RegExp(pattern.regx).test(value)
        ) {
          valid = internalValid = false
          newErrors[key] = pattern.message
        }

        const custom = validation?.custom
        if (internalValid && custom?.isValidFn && !custom.isValidFn(value)) {
          valid = internalValid = false
          newErrors[key] = custom.message
        }

        const defaultValidation = validation?.default
        if (internalValid && defaultValidation >= 0) {
          let result
          switch (defaultValidation.valueOf()) {
            case DefaultValidations.ALPHANUMERIC:
              result = AlphanumericValidation(value)
              if (!result.isValid) {
                valid = false
                newErrors[key] = result.message
              }
              break
            case DefaultValidations.NUMERIC:
              result = NumericValidation(value)
              if (!result.isValid) {
                valid = false
                newErrors[key] = result.message
              }
              break
            case DefaultValidations.NUMERICOREMPTY:
              result = NumericOrEmptyValidation(value)
              if (!result.isValid) {
                valid = false
                newErrors[key] = result.message
              }
              break
            case DefaultValidations.POSTALCODE:
              result = PostalCodeValidation(value)
              if (!result.isValid) {
                valid = false
                newErrors[key] = result.message
              }
              break
            case DefaultValidations.ALPHANUMERICANDSPECIALCHARACTERS:
              result = AlphaNumericAndSpecialCharactersValidation(value)
              if (!result.isValid) {
                valid = false
                newErrors[key] = result.message
              }
              break
            case DefaultValidations.NAMEVALIDATIONANDSPECIALCHARACTERS:
              result = NameValidation(value)
              if (!result.isValid) {
                valid = false
                newErrors[key] = result.message
              }
              break
          }
        }
      }

      if (!valid) {
        setErrors(newErrors)
        return
      }
    }

    setErrors({})

    if (options?.onSubmit) {
      options.onSubmit()
    }
  }

  useEffect(() => {
    setData(options?.initialValues)
  }, [options?.initialValues])

  return {
    data,
    handleChange,
    handleSubmit,
    errors,
    setPropertyValue,
    setPropertiesValue
  }
}
