import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { ButtonClose, Container, ContainerSkeleton, IconStyle, Item, Title, ContainerSubMenu, SubMenu, SubTitle, ContainerSecondSubMenu, SecondSubTitle, IconStyleLogOut, ContainerMenu, ContainerLogo, StyledLogo, ProfileName, UserName, UserTitle, ContainerPerfil, ButtonPerfil } from "./styled.components";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StoreUser from "../../storeUser";

const Menu = ({ routes, loading }) => {
  const [flagSubMenu, setFlagSubMenu] = useState(null);
  const [flagSubMenu2, setFlagSubMenu2] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const btnCierre = useRef();
  const [dataAuth, setDataAuth] = useState();

  const storeUser = new StoreUser();

  const dataAuth$ = storeUser.infoUser;
  

  useEffect(() => {
    const subscription = dataAuth$.subscribe((data) => setDataAuth(data));
    return () => subscription.unsubscribe();
  }, []);


  useEffect(() => {
    const result = routes?.reduce((a, b) => {
      a[b.descriptionMenu] = false;
      return a;
    }, {});
    setFlagSubMenu(result);
    setFlagSubMenu2([]);
  }, [location, routes]);


  const handleSubMenu = (e) => {
    if (!flagSubMenu) return;
    let result = {};
    let value = e.currentTarget.getAttribute("name");
    Object.keys(flagSubMenu).forEach((key) => {
      if (!value) {
        result[key] = false;
      } else {
        if (value === key) {
          result[key] = !flagSubMenu[key];
        } else {
          result[key] = false;
        }
      }
    });
    setFlagSubMenu(result);
  };

  const handleSubMenu2 = (e) => {
    if (!flagSubMenu2) return;
    let result = {};
    let value = e.currentTarget.getAttribute("name");
    Object.keys(flagSubMenu2).forEach((key) => {
      if (!value) {
        result[key] = false;
      } else {
        if (value === key) {
          result[key] = !flagSubMenu2[key];
        } else {
          result[key] = false;
        }
      }
    });
    setFlagSubMenu2(result);
  };

  const closeSession = () => {
    storeUser.deleteUserData();
    if (btnCierre.current) {
      btnCierre.current.disabled = true;
    }
    //window.location.href = "/auth/signin";
    window.location.href = "/energy/baterias/nuevo"
  };

  const renderItems = () => {
    if (!flagSubMenu || !routes) return null;

    return routes.map((menu1, index1) => (
      <Item key={index1}>
        <Title
          name={menu1.descriptionMenu}
          $visible={flagSubMenu[menu1.descriptionMenu]}
          onClick={(e) => {
            if (menu1.type === 1) {
              const result = menu1.childs?.reduce((a, b) => {
                a[b.descriptionMenu] = false;
                return a;
              }, {});
              setFlagSubMenu2(result);
              handleSubMenu(e)
            } else {
              const module = {
                module: menu1.descriptionMenu,
                permissions: menu1.permissions
              }
              storeUser.addModule(module);
              navigate(`${menu1.url}`);
            }
          }}
        >
          <div className="icon-menu">
            <IconStyle $visible={flagSubMenu[menu1.descriptionMenu]} color="red">{menu1.icon}</IconStyle>
          </div>
          <span>{menu1.descriptionMenu}</span>
        </Title>
        <ContainerSubMenu $visible={flagSubMenu[menu1.descriptionMenu]}>
          {renderSubMenu(menu1)}
        </ContainerSubMenu>
      </Item>
    ));
  };

  const renderSubMenu = (menu1) => {
    return (
      <>
        {
          menu1.childs?.length > 0 && menu1.childs.map((menu2, index2) => (
            <SubMenu
              key={index2}
              name={menu2.descriptionMenu}
              onClick={(e) => {
                if (menu2.type === 1) {
                  handleSubMenu2(e)
                } else {
                  const module = {
                    module: menu2.descriptionMenu,
                    permissions: menu2.permissions
                  }
                  storeUser.addModule(module);
                  navigate(`${menu2.url}`);

                }
              }}
            >
              <SubTitle $type={menu2.type} $visible={flagSubMenu2[menu2.descriptionMenu]}>
                <span>{menu2.descriptionMenu}</span>
                {menu2.type === 1 && <KeyboardArrowDownIcon className="selectedMenu" style={{ color: flagSubMenu2[menu2.descriptionMenu] && "#00B3B0" }} />}
              </SubTitle>
              <ContainerSecondSubMenu $visible={flagSubMenu2[menu2.descriptionMenu]}>
                {renderSecondSubMenu(menu2)}
              </ContainerSecondSubMenu>
            </SubMenu>
          ))
        }
      </>
    )
  }

  const renderSecondSubMenu = (menu2) => {
    if (!flagSubMenu2) return null;
    return (
      <>
        {
          menu2.childs?.length > 0 && menu2.childs.map((menu3, index3) => (
            <SubMenu
              key={index3}
              onClick={() => {
                const module = {
                  module: menu3.descriptionMenu,
                  permissions: menu3.permissions
                }
                storeUser.addModule(module);
                navigate(`${menu3.url}`);
              }}
            >
              <SecondSubTitle>
                <span>{menu3.descriptionMenu}</span>
              </SecondSubTitle>
            </SubMenu>
          ))
        }
      </>
    )
  }

  return (
    <>
      <Container onMouseLeave={handleSubMenu}>
        {loading ? (
          <Item>
            <ContainerSkeleton>
              <Skeleton variant="rounded" width={60} height={60} sx={{ margin: "10px 0px" }} />
              <Skeleton variant="rounded" width={60} height={60} sx={{ margin: "10px 0px" }} />
              <Skeleton variant="rounded" width={60} height={60} sx={{ margin: "10px 0px" }} />
            </ContainerSkeleton>
          </Item>
        ) : (
          <ContainerMenu>
            <ContainerLogo>
              <StyledLogo />
          </ContainerLogo>
          <ContainerPerfil>
          <ButtonPerfil className="button-perfil">
            <UserTitle>{dataAuth?.userData.initial}</UserTitle>
            <span style={{textAlign: 'left', padding: '20px'}}>{dataAuth?.userData.name}</span>
          </ButtonPerfil>
          </ContainerPerfil>
            { renderItems() }
          </ContainerMenu>
        )}
        <Item $isFinal={true}>
          <ButtonClose onClick={closeSession} ref={btnCierre} className="button-close">
            <IconStyleLogOut>icono_logout</IconStyleLogOut>
            <span>Cerrar Sesión</span>
          </ButtonClose>
        </Item> 
      </Container>
    </>
  );
};

export default Menu;
