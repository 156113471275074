import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const ButtonWithIcon = styled(Button)({
    border: `1px solid #3959A5`,
    backgroundColor: "#3959A5",
    color: "white",
    fontSize: "14px",
    padding: "3px 20px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    
    "&:hover": {
      backgroundColor: "#3959A5",
      color: "white"
    },
  });
  

export default ButtonWithIcon;
