import { Box, Typography } from "@mui/material";
import { InputStyles, TextReadOnlyStyles } from "./Input.Styled";

export const InputText = (props) => {
    return (
        <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>{props.title}</Typography>
            <InputStyles {...props} fullWidth={props.notFull ? false : true} size='normal' />
        </Box>
    );
}

export const InputTextReadOnly = (props) => {
    return (
        <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>{props.title}</Typography>
            <TextReadOnlyStyles>{props.value}</TextReadOnlyStyles>
        </Box>
    );
}