import { Grid, IconButton, InputAdornment, Typography } from "@mui/material"
import { InputText, InputTextReadOnly } from "../../../../../components/Base/Inputs"
import { useEffect, useState } from "react"
import { TitleSection } from "../../../../../components/Base/TitleSection";
import { PrimaryButton } from "../../../../../components/Base/Buttons/Buttons";
import Select from "../../../../../components/Select/Select";
import { currencyFormatter, IntegerAndDecimalValidation, ShowMessage } from "../../../../../utils/utils";
import { useForm } from "../../../../../hooks/useForm";
import { DetailGaranty } from "./DetailGaranty";
import { ConfirmationModal } from "../../../../../components/Modal/ConfirmationModal";
import { StyledIcon, StyledIconMui } from "./Garanty.Styled";
import { IconDetail } from "../../../../../components/Icon";
import { Delete } from "@mui/icons-material";
import DataTable from "../../../../../components/DataTable/DataTable";
import { Editor } from "../../../../../components/Editor";
import { useGetTimeWarranty } from "../../../api/BatteryApi";

const COLUMNS = [
    { id: "id", label: "ID" },
    { id: "timeWarrantyName", label: "Tiempo de garantía" },
    { id: "costKw", label: "Costo kW/h" },
    { id: "desiredUtility", label: "Utilidad" },
    { id: "priceUser", label: "Precio de usuario" },
    { id: "actions", label: "Acciones" },
];

export const ConfigurationBattery = ({
    id,
    listWarranty,
    setListWarranty
}) => {
    const [priceUser, setPriceUser] = useState("");
    const warrantyById = null;
    const [showModal, setshowModal] = useState(false);
    const [selectWarranty, setSelectWarranty] = useState(null);
    const [modelTable, setModelTable] = useState([]);
    const [showConfimationModal, setShowConfirmationModal] = useState(false);
    const [dataJSON, setDataJSON] = useState("");
    const timeWarrantyQuery = useGetTimeWarranty();
    const [optionsTimeWarranty, setOptionsTimeWarranty] = useState([]);
    const [characterisWarranty, setCharacterisWarranty] = useState("");

    const { setPropertiesValue, handleSubmit, handleChange, data: warrantyData, errors } = useForm({
        initialValues: warrantyById?.data,
        validations: {
            timeWarrantyId: {
                required: { message: "El tiempo de garantía es requerido" },
                custom: {
                    isValidFn: (value) => {
                        return (value && value !== "");
                    },
                    message: "La marca debe de contar con menos de 51 carácteres"
                },
            },
            costKw: {
                required: { message: "El costo es requerido" },
            },
            desiredUtility: {
                required: { message: "La utilidad deseada es requerido" },
            }
        },
        onSubmit: async () => {
            if (!id) {
                const auxData = JSON.parse(JSON.stringify(listWarranty ?? []))
                const duplicate = auxData.find(x => x.timeWarrantyId === warrantyData?.timeWarrantyId);
                if (!duplicate) {
                    const nameTimeWarranty = optionsTimeWarranty.find(x => x.value === warrantyData?.timeWarrantyId);
                    auxData.push({
                        id: 0,
                        timeWarrantyId: warrantyData?.timeWarrantyId,
                        timeWarrantyName: nameTimeWarranty?.label,
                        costKw: warrantyData?.costKw,
                        desiredUtility: warrantyData?.desiredUtility,
                        priceUser: warrantyData?.priceUser,
                        characteristics: JSON.stringify(characterisWarranty)
                    });
                    setListWarranty(auxData);
                } else {
                    ShowMessage("El tiempo de Garantía ya se encuentra agregado", "warning")
                }
            }
        }
    });

    const handleCostKw = (value) => {
        const validation = IntegerAndDecimalValidation(value, 10, 4).isValid;
        const condition = value && warrantyData?.desiredUtility && value !== "" && warrantyData?.desiredUtility !== ""
        if (validation) {
            const valuePorcentage = (parseFloat(value) * (parseInt(warrantyData?.desiredUtility) / 100)) + parseFloat(value);
            getPriceUserValue(condition, valuePorcentage, { costKw: value });
        } else if (value === "") {
            getPriceUserValue(condition, "", { costKw: "" });
        }
    }

    const handleDesireUtilityValue = (value) => {
        if (value < 0) {
            value = 0
        } else if (value > 100) {
            value = 100
        }
        const condition = warrantyData?.costKw && value && warrantyData?.costKw !== "" && value !== ""
        const valuePorcentage = (parseFloat(warrantyData?.costKw) * (parseInt(value) / 100)) + parseFloat(warrantyData?.costKw);
        getPriceUserValue(condition, valuePorcentage, { desiredUtility: value });
    }

    const getPriceUserValue = (condition, valuePorcentage, objectValues) => {
        if (condition) {
            setPropertiesValue({ ...objectValues, priceUser: valuePorcentage })
            setPriceUser(currencyFormatter({ style: "currency", currency: "MXN", value: valuePorcentage, minFractionDigits: 4 }))
        } else {
            setPropertiesValue({ ...objectValues, priceUser: "" });
            setPriceUser("");
        }
    }

    const handleDeleteGaranty = () => {
        const auxData = JSON.parse(JSON.stringify(listWarranty ?? []))
        const findGaranty = auxData.findIndex((x) => x.id === selectWarranty.id);
        auxData.splice(findGaranty, 1);
        setShowConfirmationModal(false);
        setListWarranty(auxData);
    }

    const handleClick = async () => {
        await handleSubmit()
    }

    useEffect(() => {
        if (listWarranty && Array.isArray(listWarranty)) {
            const auxData = JSON.parse(JSON.stringify(listWarranty ?? []))
            const models = auxData.map((warranty) => {
                return {
                    id: (warranty.id === 0) ? "-" : warranty.id,
                    timeWarrantyName: warranty?.timeWarrantyName,
                    costKw: warranty.costKw,
                    desiredUtility: warranty.desiredUtility,
                    priceUser: currencyFormatter({ style: "currency", currency: "MXN", value: warranty.priceUser, minFractionDigits: 4 }),
                    characteristics: warranty.characteristics,
                    actions: (
                        <Grid container justifyContent="center" alignItems={"center"}>
                            <Grid item>
                                <IconButton
                                    onClick={() => {
                                        setSelectWarranty(JSON.parse(JSON.stringify(warranty)));
                                        setshowModal(true);
                                    }}>
                                    <StyledIcon>
                                        <IconDetail />
                                    </StyledIcon>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => {
                                    setSelectWarranty(JSON.parse(JSON.stringify(warranty)));
                                    setShowConfirmationModal(true);
                                }}>
                                    <StyledIconMui bgColor={"#d44240"}>
                                        <Delete />
                                    </StyledIconMui>
                                </IconButton>
                            </Grid>
                        </Grid>
                    ),
                }
            });
            setModelTable(models);
        }
    }, [listWarranty]);

    useEffect(() => {
        if(dataJSON){
            setCharacterisWarranty(dataJSON);
        }
    }, [dataJSON]);

    useEffect(() => {
        if (warrantyData?.characteristics) {
            handleClick()
        }
    }, [warrantyData?.characteristics]);

    useEffect(() => {
        if (timeWarrantyQuery.isSuccess && timeWarrantyQuery.data) {
            const auxData = [];
            timeWarrantyQuery.data.map((value) => {
                auxData.push({
                    value: value.value,
                    label: value.description
                })
            });
            setOptionsTimeWarranty(auxData);
        }
    }, [timeWarrantyQuery.isSuccess])

    return (
        <>
            <Grid container columnSpacing={3}>
                <TitleSection title={"Configuración de garantía"} />
                <Grid item xs={3} mt={2}>
                    <Typography variant="subtitle2" gutterBottom>Tiempo de garantía</Typography>
                    <Select
                        options={optionsTimeWarranty}
                        onChange={(value) => setPropertiesValue({ timeWarrantyId: value })}
                        error={errors && errors.timeWarrantyId}
                        helperText={errors.timeWarrantyId || ""}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputText
                        fullWidth
                        type={"number"}
                        title="Costo Kw/h"
                        value={warrantyData?.costKw}
                        onChange={(e) => handleCostKw(e.target.value)}
                        error={errors && errors.costKw}
                        helperText={errors.costKw || ""}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputText
                        type="number"
                        title="Utilidad deseada"
                        value={warrantyData?.desiredUtility}
                        onChange={(e) => handleDesireUtilityValue(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        error={errors && errors.desiredUtility}
                        helperText={errors.desiredUtility || ""}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputTextReadOnly
                        title="Precio al usuario"
                        value={priceUser}
                    />
                </Grid>
                <Grid item xs={12} mt={3}>
                    <Typography variant="subtitle2" gutterBottom>Características de garantía</Typography>
                    <Editor
                        limit={5000}
                        disabled={false}
                        setJSON={(dataJSON) => setDataJSON(dataJSON)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <PrimaryButton
                        handleClick={async () => await handleSubmit()}
                        text={"Agregar"}
                        bgColor="#33c959"
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        columns={COLUMNS}
                        data={modelTable}
                        adjustColumn={["actions"]}
                    />
                </Grid>
            </Grid>
            <DetailGaranty
                data={selectWarranty}
                open={showModal}
                close={() => setshowModal(false)}
            />
            <ConfirmationModal
                open={showConfimationModal}
                onClose={() => setShowConfirmationModal(false)}
                onSucces={() => handleDeleteGaranty()}
                isAvailableCancel={true}
                isAvailableAcept={true}
                message={`¿Está seguro que desea eliminar la garantía "${selectWarranty?.timeWarrantyName ?? ""}"?`}
                status={"warning"}
            />
        </>
    )
}