import { Button, Icon, IconButton } from "@mui/material";
import { styled } from "styled-components";

export const PrimaryButtonStyle = styled(Button)`
    padding: 5px 15px !important;
    box-shadow: ${p => p.bgColor ?? "#496fee"}!important;
    background-color: ${p => p.bgColor ?? "#496fee"} !important;
    color: white !important;
    width: 50%;
    :hover{
        background-color: transparent !important;
    }
    .textButton{
        display: block !important;
    }
    .iconTeam{
        font-size: 25px !important;
    }
`;

export const SecondaryButtonStyle = styled(Button)`
    border-radius: 4px !important;
    border: 1px solid ${p => p.bgColor ?? "#c04f57"} !important;
    color: ${p => p.bgColor ?? "#c04f57"} !important;
    padding: 5px 15px !important;
    width: 50%;
`;

export const PrimaryIconButtonStyle = styled(IconButton)`
    background-color: ${p => p.bgColor ?? "#496fee"} !important;
    border-radius: 10% !important;
    color: white !important;
    height: "auto" !important;
    width: "auto" !important;
    :hover {
        background-color: ${p => p.bgColor ?? "#496fee"} !important; 
    }
`;

export const StyledIcon = styled(Icon)`
  width: auto !important;
  height: auto !important;
  svg{
    width: 25px;
    height: 25px;
    border-radius: 5px;

    rect{
      fill: ${props => props.bgColor ?? "#2756F3"};
    }
  }
`;

export const StyledIconMui = styled(Icon)`
  width: auto !important;
  height: auto !important;
  svg{
    width: 25px;
    height: 25px;
    border-radius: 5px;
    background-color: ${props => props.bgColor ?? "#2756F3"};
    color: white;
  }
`;
