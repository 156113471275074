import styled from 'styled-components';

export const SelectContainer = styled.div`
  position: relative;
  width: ${(props) => props.customWidth || '100%'};
  min-width: ${(props) => props.customMinWidth || '300px'};
  max-width: ${(props) => props.customMaxWidth || '600px'};
  background-color: ${(props) => props.customBackgroundColor || 'inherit'};
`;

export const SelectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.customPadding || '8px 10px'};
  border: 1px solid ${(props) => (!props.error) ? "#ccc" : "#d32f2f"};
  border-radius: 4px;
  cursor: pointer;
  outline: none;

  &:focus {
    box-shadow: 0 0 0 2px rgba(21, 156, 228, 0.4);
  }
`;

export const SelectIcon = styled.div`
  transition: transform 0.3s;
  transform: rotate(-90deg);
  ${({ isOpen }) => isOpen && 'transform: rotate(90deg);'}
`;

export const SelectList = styled.ul`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  max-height: 150px;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 8px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 1;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SelectListItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 10px;
`;

export const SearchInput = styled.input`
  width: calc(100% - 20px);
  padding: 8px 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;

  &:focus {
    border-color: #4a90e2;
  }
`;
