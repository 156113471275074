import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TariffProcess from '../views/TariffProcess/TariffProcess';
import TariffProcessDetail from '../views/TariffProcessDetail/TariffProcessDetail';
import { CalculationsContainer } from '../views/Calculations/CalculationsContainer';
import { NewCalculations } from '../views/Calculations/components/NewCalculations';
import { UserManagementContainer } from '../views/UserManagement/UserManagementContainer';
import { BatteryContainer } from '../views/BatteryCatalog/BatteryContainer';
import { FormNewBattery } from '../views/BatteryCatalog/components/NewBattery';
import { ProjectionsContainer } from '../views/Projections/ProjectionsContainer';
import { ExchangeRate  } from '../views/ExchangeRate';

const Router = () => {
  return (
    <Routes>
        <Route path="/tarifa" element={<TariffProcess />} /> 
        <Route path="/tarifa/detalle/:id" element={<TariffProcessDetail />} />
        <Route path="/calculos" element={<CalculationsContainer/>}/>
        <Route path="/calculos/nuevo" element={<NewCalculations/>}/>
        <Route path="/calculos/detalle/:id" element={<NewCalculations/>}/>
        <Route path="/usuarios" element={<UserManagementContainer />} />
        <Route path="/inversion/baterias" element={<BatteryContainer />} />
        <Route path="/inversion/baterias/nuevo" element={<FormNewBattery />} />
        <Route path="/inversion/baterias/detalle/:id" element={<FormNewBattery />} />
        <Route path="/inversion/inversiones" element={<ProjectionsContainer />} />
        <Route path='/inversion/configuraciones' element={<ExchangeRate/>}/>
    </Routes>
  );
};

export default Router;