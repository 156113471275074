import { useNavigate } from "react-router-dom";
import { Grid, IconButton } from "@mui/material"
import CustomTemplate from "../../components/CustomTemplate/CustomTemplate"
import DataTable from "../../components/DataTable/DataTable"
import CustomButton from "../../components/CustomButtonStyled/CustomButton";
import { useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";
import { IconDetail } from "../../components/Icon";
import { ConfirmationModal } from "../../components/Modal/ConfirmationModal"
import { ProjectionsProvider } from "../../utils/context/projectionsContext";
import { dataProjections } from "./interface/Data";
import { IconButtonMui, IconButtonNormal } from "../../components/Base/Buttons/Buttons";

export const ProjectionsContainer = () => {
    const history = useNavigate();
    const [modelTable, setModelTable] = useState([]);
    const [showConfimationModal, setShowConfirmationModal] = useState(false);
    const [selectBattery, setSelectBattery] = useState(null);
    const batteriesQuery = dataProjections;
    //const deleteBattery = useDeleteBatteries();

    const columns = [
        { id: "id", label: "ID" },
        { id: "nameProjection", label: "Nombre de la proyección de inversión" },
        { id: "megaWatts", label: "MegaWatts" },
        { id: "downloadTime", label: "Tiempo de descarga (hrs)" },
        { id: "actions", label: "Acciones" },
    ];

    const handleDeleteProjection = async () => {
        //await deleteBattery.mutateAsync(selectBattery.id);
        setShowConfirmationModal(false);
        //batteriesQuery.refetch();
    }

    useEffect(() => {
        if (batteriesQuery) {
            const auxData = JSON.parse(JSON.stringify(batteriesQuery ?? []));
            handleSetterModelsTable(auxData);
        }
    }, [batteriesQuery]);

    const handleSetterModelsTable = (dataTable) => {
        const models = dataTable.map((projection) => {
            return {
                id: projection.id,
                nameProjection: projection.nameProjection,
                megaWatts: projection.megaWatts,
                downloadTime: projection.downloadTime,
                actions: (
                    <Grid container justifyContent="center" alignItems={"center"}>
                        <Grid item>
                            <IconButton
                                onClick={() => {
                                    //history(`detalle/${projection?.id}`)
                                }}>
                                <IconButtonNormal>
                                    <IconDetail />
                                </IconButtonNormal>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => {
                                setSelectBattery(JSON.parse(JSON.stringify(projection)));
                                setShowConfirmationModal(true);
                            }}>
                                <IconButtonMui bgColor={"#d44240"}>
                                    <Delete />
                                </IconButtonMui>
                            </IconButton>
                        </Grid>
                    </Grid>
                ),
            }
        });
        setModelTable(models);
    }

    return (
        <ProjectionsProvider>
            <CustomTemplate title="Registro de Proyecciones de Inversión">
                <Grid container>
                    <Grid item xs={12}>
                        <DataTable
                            columns={columns}
                            data={modelTable}
                            adjustColumn={["actions"]}
                            filterable
                            customBtn={
                                (<Grid item xs={6} container justifyContent={"right"}>
                                    <Grid item>
                                        <CustomButton
                                            variant="outlined"
                                            //onClick={() => history(`nuevo`)}
                                        >
                                            Nueva proyección
                                        </CustomButton>
                                    </Grid>
                                </Grid>)
                            }
                        />
                    </Grid>
                </Grid>
                <ConfirmationModal
                    open={showConfimationModal}
                    onClose={() => setShowConfirmationModal(false)}
                    onSucces={() => handleDeleteProjection()}
                    isAvailableCancel={true}
                    isAvailableAcept={true}
                    message={`¿Está seguro que desea eliminar la projection de inversión "${selectBattery?.nameProjection}"?`}
                    status={"warning"}
                />
            </CustomTemplate>
        </ProjectionsProvider>
    )
}