import { Grid } from "@mui/material"
import CustomTemplate from "../../components/CustomTemplate/CustomTemplate"
import { ContentSection } from "../../components/Base/TitleSection/TitleSection.Style"
import { TitleSection } from "../../components/Base/TitleSection"
import { InputText } from "../../components/Base/Inputs"
import { useForm } from "../../hooks/useForm"
import { IntegerAndDecimalValidation } from "../../utils/utils"
import { PrimaryButton } from "../../components/Base/Buttons/Buttons"

export const ExchangeRate = () => {

    const { setPropertiesValue, handleSubmit, handleChange, data: rateData, errors } = useForm({
        initialValues: null,
        validations: {
            rate: {
                required: { message: "El tipo de cambio es requerido" },
            },
        },
        onSubmit: async () => {

        }
    });

    const handleRate = (value) => {
        if (IntegerAndDecimalValidation(value, 10, 4).isValid) {
            setPropertiesValue({ rate: value });
        } else if (value === "") {
            setPropertiesValue({ rate: "" });
        }
    }

    return (
        <CustomTemplate title="Panel de configuración de proyección de inversión" hasButtonBack>
            <ContentSection container>
                <Grid container columnSpacing={3}>
                    <TitleSection title={"Tipo de cambio"} />
                    <Grid item>
                        <InputText
                            fullWidth
                            type={"number"}
                            title="Tipo de cambio (pesos)"
                            value={rateData?.rate}
                            onChange={(e) => handleRate(e.target.value)}
                            error={errors && errors.rate}
                            helperText={errors.rate || ""}
                        />
                    </Grid>
                    <Grid container justifyContent={"center"} alignItems={"center"} textAlign={"center"} marginTop={5}>
                        <Grid item xs={4}>
                            <PrimaryButton
                                handleClick={async () => await handleSubmit()}
                                text={"Aplicar"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </ContentSection>
        </CustomTemplate>
    )
}