import { PrimaryButtonStyle, SecondaryButtonStyle, PrimaryIconButtonStyle, StyledIcon, StyledIconMui } from "./Buttons.Styled"

export const PrimaryButton = (props) => {
    const { text, bgColor, handleClick, ...others } = props;
    return (
        <PrimaryButtonStyle variant="contained" bgColor={bgColor} onClick={handleClick} {...others}>
            <span className="textButton"> {text} </span>
        </PrimaryButtonStyle>
    )
}

export const SecondaryButton = (props) => {
    const { text, bgColor, handleClick, ...others } = props;
    return (
        <SecondaryButtonStyle variant="outlined" bgColor={bgColor} onClick={handleClick} {...others}>
            <span className="textButton"> {text} </span>
        </SecondaryButtonStyle>
    )
}

export const PrimaryIconButton = (props) => {
    return (
        <PrimaryIconButtonStyle variant="contained" bgColor={props.bgColor} onClick={props.handleClick}>
            {props.children}
        </PrimaryIconButtonStyle>
    )
}

export const IconButtonNormal = (props) => {
    return (
        <StyledIcon>
            {props.children}
        </StyledIcon>
    )
}

export const IconButtonMui = (props) => {
    return (
        <StyledIconMui  bgColor={props.bgColor}>
            {props.children}
        </StyledIconMui>
    )
}