import { createContext, useState } from 'react';

const ProjectionsContext = createContext("");

const ProjectionsProvider =  (props) => {
    const [state, setState] = useState();

    return(
        <ProjectionsContext.Provider value={[state, setState]}>
            {props.children}
        </ProjectionsContext.Provider>
    )
}

export { ProjectionsContext, ProjectionsProvider };